function App() {
  return (
    <div className="flex flex-col justify-center items-center min-h-[100svh] w-full bg-gradient-to-br from-[#A53EFF] to-[#FF8D00] font-mono">
      {/* <div className="flex justify-center w-1/3">
        <img
          src="/banner.png"
          alt="Molecule Banner"
          className="max-w-[400px]"
        />
      </div> */}
      <div className="flex justify-center w-1/3">
        <img
          src="/logo-white-text.png"
          alt="Molecule Logo"
          className="max-w-[300px]"
        />
      </div>
      <div className="w-11/12 mt-9 text-white text-center text-[0.65rem] sm:text-sm md:text-base">
        <div>The Most Performant Bitcoin-native Layer for dApps.</div>
        <div>Powered by Solana Virtual Machine.</div>
      </div>
      <div className="mt-9 text-white text-2xl font-semibold font-mono">
        Coming Soon.
      </div>
      <button
        onClick={() => window.open("https://twitter.com/moleculebtc", "_blank")}
        className="bg-black text-white px-4 py-2 rounded-lg mt-2"
      >
        stay updated {`>`}
      </button>
    </div>
  );
}

export default App;
